var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"80svw"},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}},[_c('div',{staticClass:"modalBackground"},[_c('p',{staticStyle:{"font-size":"20px","margin-bottom":"0px"}},[_vm._v("Квитки користувача")]),_c('div',{staticClass:"sectionLine",staticStyle:{"margin":"20px 0px"}}),_c('v-row',{staticStyle:{"margin":"20px 0px"},attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"4"}},[_c('search-field',{attrs:{"label":"Пошук квитка","placeholder":"Введіть тут.."}})],1),_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('div',{staticClass:"statusBox",class:_vm.activeStatus == 'all' ? 'activeStatusBox' : '',on:{"click":function($event){_vm.activeStatus = 'all'}}},[_vm._v(" Усі ")]),_c('div',{staticClass:"statusBox",class:_vm.activeStatus == 'Payed' ? 'activeStatusBox' : '',on:{"click":function($event){_vm.activeStatus = 'Payed'}}},[_vm._v(" Оплачено ")]),_c('div',{staticClass:"statusBox",class:_vm.activeStatus == 'Canceled' ? 'activeStatusBox' : '',staticStyle:{"margin-right":"0px"},on:{"click":function($event){_vm.activeStatus = 'Canceled'}}},[_vm._v(" Скасовано ")])])],1),(_vm.showLoader)?_c('loader'):_c('v-data-table',{staticStyle:{"margin-top":"32px"},attrs:{"headers":_vm.headers,"items":_vm.ticketList,"hide-default-footer":"","hide-default-header":"","items-per-page":100,"background-color":"red","no-data-text":"Поки що ви не додали жодного маршруту"},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return _vm._l((props.headers),function(head){return _c('th',{key:head.id,staticStyle:{"padding":"15px"},on:{"click":function($event){!head.sortable && head.sortable !== false
              ? (_vm.sortBy = head.value)((_vm.sortDesc = !_vm.sortDesc))
              : ''}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":head.align ? head.align : 'start'}},[_c('output',{staticStyle:{"font-weight":"500","font-size":"16px","line-height":"22px","letter-spacing":"0.1em","color":"#1b1b1b","cursor":"pointer","user-select":"none"}},[_vm._v(_vm._s(head.text)+" "),(head.sortable !== false)?_c('v-icon',{attrs:{"size":"25px","color":head.value == _vm.sortBy ? 'black' : ''}},[_vm._v("mdi-menu-swap")]):_vm._e()],1)])],1)})}},{key:`item.id`,fn:function({ item }){return [_c('div',{staticStyle:{"width":"180px"}},[_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(item.id))])])]}},{key:`item.route`,fn:function({ item }){return [_c('div',{staticStyle:{"width":"120px","text-overflow":"ellipsis"}},[_c('span',{staticClass:"tableItem",staticStyle:{"color":"#144fa9 !important"}},[_vm._v(_vm._s(item?.schedule?.route?.departure?.translations?.find( (translate) => translate.lang == "ua" )?.name)+"-"+_vm._s(item?.schedule?.route?.destination?.translations?.find( (translate) => translate.lang == "ua" )?.name))])])]}},{key:`item.purchase_at`,fn:function({ item }){return [_c('span',{staticClass:"tableItem"},[_vm._v(" "+_vm._s(new Date(item?.created_at).toLocaleTimeString("uk-UA", { hour: "2-digit", minute: "2-digit", timeZone: "Europe/Kiev", }))+" "+_vm._s(new Date(item?.created_at).toLocaleDateString("uk-UA", { month: "2-digit", year: "numeric", day: "2-digit", timeZone: "Europe/Kiev", })))])]}},{key:`item.qt`,fn:function({ item }){return [_c('span',{staticClass:"tableItem"},[_vm._v(" "+_vm._s(item?.seats_count))])]}},{key:`item.status`,fn:function({ item }){return [_c('div',{staticStyle:{"width":"200px"}},[_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"statusBox statusName",style:(item.status == 'Payed'
                    ? ' background-color:rgba(20, 158, 81, 0.10);color: #149e51;'
                    : ' background-color:rgba(223, 58, 58, 0.10); color: #DF3A3A')},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.status == "Payed" ? "Оплачено" : item.status == "Canceled" ? "Скасовано" : item.status == "NotPayed" ? "Не оплачено" : "Помилка")+" "),_c('v-icon',{attrs:{"size":"18px","color":item.status == 'Payed' ? '#149E51' : '#DF3A3A'}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('div',{staticClass:"statusName",staticStyle:{"border-radius":"9px","background":"#fff","padding":"8px 16px","display":"grid","place-items":"center"},style:(item.status !== 'Payed'
                  ? 'color: #149E51;'
                  : 'color: #DF3A3A;')},[_vm._v(" "+_vm._s(item.status !== "Payed" ? "Оплачено" : item.status !== "Canceled" ? "Скасовано" : "Помилка")+" ")])])],1)]}},{key:`item.bonus`,fn:function({ item }){return [_c('span',{staticClass:"tableItem",staticStyle:{"color":"#149e51"}},[_vm._v(" -"+_vm._s(item?.bonus))])]}},{key:`item.action`,fn:function({ item }){return [_c('div',{staticStyle:{"width":"145px"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('div',{staticClass:"detailBtn",staticStyle:{"margin-right":"12px"},on:{"click":function($event){(_vm.showDetailOrderDrawer = true), (_vm.detailOrder = item)}}},[_vm._v(" Детальніше ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"iconBackground"},[_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"color":"#828282"},on:{"click":function($event){(_vm.symlinks = item.symlinks),
                        (_vm.showDetailSymlinkModal = true)}}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v("Деталі")])])],1)],1)]}}],null,true)}),_c('pagination',{attrs:{"page":_vm.page,"quantityPage":_vm.quantityPage},on:{"changePage":_vm.changePage}})],1),(_vm.showDetailOrderDrawer)?_c('detail-order-drawer',{staticStyle:{"margin-top":"5svh"},attrs:{"order":_vm.detailOrder},on:{"close":function($event){_vm.showDetailOrderDrawer = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }