import requestService from "../requestService";
import store from '@/store';

export default {
    async getUsers(page, search, sortBy, sortDesc) {
        const response = await requestService.get(`/cp_dash/users?search=${search}&page=${page}&order=${sortBy}&direction=${sortDesc}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getUser(id) {
        const response = await requestService.get(`/cp_dash/users/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getManagers(page) {
        const response = await requestService.get(`/cp_dash/users?search=[role_id[in]:1,2,3,4]&page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getUserOrders(user_id) {
        const response = await requestService.get(`/cp_dash/users/${user_id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async createUser(form) {
        const response = await requestService.post(`/cp_dash/users`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async updateUser(id, form) {
        const response = await requestService.post(`/cp_dash/users/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async deleteUser(user_id) {
        const response = await requestService.delete(`/cp_dash/users/${user_id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
}