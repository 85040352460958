<template>
  <v-col class="px-0 py-0">
    <statistic-card
      :numbers="totalUsers"
      title="Верифікованих користувачів"
      subtitle="Користувачі, які зареєстрвалися на сайті"
      style="margin: 20px 0px"
    />
    <v-col cols="4" class="px-0 py-0" style="margin: 20px 0px">
      <search-field
        label="Пошук"
        placeholder="Введіть тут.."
        @search="setSearchField"
      />
    </v-col>
    <users-list :users="usersList" :loading="loading" @sortBy="setSortField" />
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />
  </v-col>
</template>

<script>
import usersService from "../../../requests/Admin/usersService";
import statisticCard from "../../UI/Cards/statisticCard.vue";
import SearchField from "../../UI/Fields/searchField.vue";
import Pagination from "../../UI/pagination.vue";
import UsersList from "./usersList.vue";
export default {
  components: { statisticCard, SearchField, UsersList, Pagination },
  data: () => ({
    usersList: [],
    loading: true,
    totalUsers: 0,
    page: 1,
    quantityPage: 0,
    search: `[role_id[eq]:5]`,
    sortBy: "",
    sortDesc: "",
  }),
  mounted() {
    this.getUsers();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.loading = true;
      this.getUsers();
    },
    setSearchField(search) {
      this.search = `[role_id[eq]:5;all[like]:${search}]`;
      this.loading = true;
      this.getUsers();
    },
    setSortField(sortBy, sortDesc) {
      this.sortBy = sortBy;
      this.sortDesc = sortDesc == true ? "DESC" : "ASC";
      this.getUsers();
    },
    async getUsers() {
      await usersService
        .getUsers(this.page, this.search, this.sortBy, this.sortDesc)
        .then((res) => {
          if (res.status == "Success") {
            this.usersList = res.data;
            this.totalUsers = res.pagination.meta.total;
            this.quantityPage = res.pagination.meta.last_page;
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>