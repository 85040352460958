<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    right
    width="440px"
    height="90svh"
    temporary
    style="border-radius: 10px 0px 0px 10px; position: fixed"
  >
    <div class="backgroundDrawer">
      <v-row no-gutters align="center" style="margin-bottom: 20px">
        <span class="drawerTitle">Деталі користувача</span>
        <v-row no-gutters align="center" justify="end">
          <div
            class="closeIcon"
            style="cursor: pointer"
            @click="$emit('close')"
          />
        </v-row>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="
          border-bottom: 1px solid #f4f4f4;
          padding: 8px 0px;
          margin-top: 20px;
        "
      >
        <v-col cols="4" class="px-0 py-0">Користувач</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >{{ user.first_name }} {{ user.last_name }}</v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Номер телефону</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px">{{
          user.phone_number
        }}</v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Електронна адреса</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          ><a :href="`mailto: ${user.email}`">
            {{ user.email }}
          </a></v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Дата реєстрації</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px">{{
          new Date(user.created_at).toLocaleDateString("uk-UA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "Europe/Kiev",
          })
        }}</v-col>
      </v-row>
      <p class="drawerSubtitle">Квитки</p>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Кількість куплених квитків</v-col>
        <v-col
          cols="8"
          class="py-0"
          style="padding-left: 20px; cursor: pointer"
          @click="(status = 'all'), (showUserTicketModal = true)"
        >
          <v-row no-gutters align="center">
            <span style="color: #144fa9 !important">{{
              userDetail.order_count
            }}</span>
            <v-row no-gutters justify="end" align="center">
              <div class="detailUserIcon" />
            </v-row>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Активні квитки</v-col>
        <v-col
          cols="8"
          class="py-0"
          style="padding-left: 20px; cursor: pointer"
          @click="(status = 'Payed'), (showUserTicketModal = true)"
        >
          <v-row no-gutters align="center">
            <span style="color: #149e51">{{ userDetail.active_order }}</span>
            <v-row no-gutters justify="end" align="center">
              <div class="detailUserIcon" />
            </v-row>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Скасовані квитки</v-col>
        <v-col
          cols="8"
          class="py-0"
          style="padding-left: 20px; cursor: pointer"
          @click="(status = 'Canceled'), (showUserTicketModal = true)"
        >
          <v-row no-gutters align="center">
            <span style="color: #df3a3a">
              {{ userDetail.canceled_order }}
            </span>
            <v-row no-gutters justify="end" align="center">
              <div class="detailUserIcon" />
            </v-row> </v-row
        ></v-col>
      </v-row>
      <p class="drawerSubtitle">Бонуси</p>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Бонуси на рахунку</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px">{{
          userDetail.bonus
        }}</v-col>
      </v-row>
      <p class="drawerSubtitle">Звіт по продажах</p>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Загальна сума</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >{{ userDetail.total_payed }} грн</v-col
        >
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="4" class="px-0 py-0">Списано бонусами</v-col>
        <v-col cols="8" class="py-0" style="padding-left: 20px"
          >{{ userDetail.used_bonus }} балів</v-col
        >
      </v-row>
      <v-col class="px-0 py-0" style="position: absolute; bottom: 20px">
        <submit-button
          style="width: 400px !important; height: 48px !important"
          @click="$emit('close')"
          >Закрити</submit-button
        >
        <v-btn class="deleteBtn" @click="deleteUser(user.id)"
          >Видалити користувача</v-btn
        >
      </v-col>
    </div>
    <users-tickets-modal
      v-if="showUserTicketModal"
      :visible="showUserTicketModal"
      :status="status"
      :user_id="user.id"
      @close="showUserTicketModal = false"
    />
  </v-navigation-drawer>
</template>

<script>
import usersService from "../../../requests/Admin/usersService";
import submitButton from "../../UI/Buttons/submitButton.vue";
import UsersTicketsModal from "./usersTicketsModal.vue";
export default {
  components: { submitButton, UsersTicketsModal },
  data: () => ({
    drawer: true,
    showUserTicketModal: false,
    tickets: 0,
    canceled_ticket: 0,
    payed_ticket: 0,
    status: null,
    userDetail: {},
  }),
  props: {
    user: {
      require: true,
    },
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      await usersService.getUserOrders(this.user.id).then((res) => {
        if (res.status == "success") {
          this.userDetail = res.data;
        }
      });
    },
    async deleteUser(user_id) {
      await usersService.deleteUser(user_id);
    },
  },
  watch: {
    drawer: {
      handler() {
        if (!this.drawer) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped>
.backgroundDrawer {
  border-radius: 10px 0px 0px 10px;
  background: #fff;
  padding: 20px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.drawerSubtitle {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 0px;
}
.drawerTitle {
  font-size: 20px;
  font-weight: 700;
}
.deleteBtn {
  border-radius: 10px;
  background: rgba(223, 58, 58, 0.1) !important;
  height: 46px !important;
  margin-top: 10px;
  width: 400px;
  padding: 12px 16px;
  display: grid;
  place-items: center;
  font-size: 16px !important;
  font-weight: 400;
  color: #df3a3a !important;
  text-transform: none !important;
}
</style>