var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticStyle:{"margin-top":"32px"},attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"hide-default-footer":"","hide-default-header":"","items-per-page":100,"background-color":"red","no-data-text":"Поки що ви не додали жодного маршруту"},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return _vm._l((props.headers),function(head){return _c('th',{key:head.id,staticStyle:{"padding":"15px"},on:{"click":function($event){head.sortable && head.sortable !== false
            ? _vm.setSortField(head.value)
            : ''}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":head.align ? head.align : 'start'}},[_c('output',{staticStyle:{"font-weight":"500","font-size":"16px","line-height":"22px","letter-spacing":"0.1em","color":"#1b1b1b","cursor":"pointer","user-select":"none"}},[_vm._v(_vm._s(head.text)+" "),(head.sortable !== false)?_c('v-icon',{attrs:{"size":"25px","color":head.value == _vm.sortBy ? 'black' : ''}},[_vm._v("mdi-menu-swap")]):_vm._e()],1)])],1)})}},{key:`item.id`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}}),_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(item.id))])]}},{key:`item.user`,fn:function({ item }){return [_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(item?.first_name)+" "+_vm._s(item?.last_name))])]}},{key:`item.phone_number`,fn:function({ item }){return [_c('span',{staticClass:"tableItem"},[_vm._v(_vm._s(item?.phone_number))])]}},{key:`item.order_count`,fn:function({ item }){return [_c('span',{staticClass:"tableItem"},[_vm._v(" "+_vm._s(item?.order_count))])]}},{key:`item.is_popular`,fn:function({ item }){return [_c('div',{staticStyle:{"width":"140px"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-switch',{attrs:{"inset":""},model:{value:(item.is_popular),callback:function ($$v) {_vm.$set(item, "is_popular", $$v)},expression:"item.is_popular"}})],1)],1)]}},{key:`item.status`,fn:function({ item }){return [_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"statusBox statusName",style:(item.status == 'Active'
                ? '  color: #149e51;'
                : '  color: #DF3A3A')},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.status == "Active" ? "Активний" : "Деактивовано")+" "),_c('v-icon',{attrs:{"size":"18px","color":item.status == 'Active' ? '#149E51' : '#DF3A3A'}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('div',{staticClass:"statusName",staticStyle:{"border-radius":"9px","background":"rgba(223, 58, 58, 0.1)","padding":"8px 16px","display":"grid","place-items":"center"},style:(item.status !== 'Active'
              ? '  color: #149E51;'
              : '  color: #DF3A3A;'),on:{"click":function($event){return _vm.changeRouteStatus(item)}}},[_vm._v(" "+_vm._s(item.status !== "Active" ? "Активний" : "Деактивовано")+" ")])])]}},{key:`item.action`,fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('div',{staticClass:"detailBtn",staticStyle:{"margin-right":"12px"},on:{"click":function($event){(_vm.showDetailUserDrawer = true), (_vm.detailUser = item)}}},[_vm._v(" Детальніше ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"iconBackground"},[_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"color":"#828282"},on:{"click":function($event){(_vm.symlinks = item.symlinks), (_vm.showDetailSymlinkModal = true)}}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v("Деталі")])])],1)]}}],null,true)}),(_vm.showDetailUserDrawer)?_c('detail-user-drawer',{staticStyle:{"margin-top":"5svh"},attrs:{"user":_vm.detailUser},on:{"close":function($event){_vm.showDetailUserDrawer = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }