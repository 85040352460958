<template>
  <v-col class="px-0 py-0">
    <v-data-table
      style="margin-top: 32px"
      :headers="headers"
      :items="users"
      :loading="loading"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      background-color="red"
      no-data-text="Поки що ви не додали жодного маршруту"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            head.sortable && head.sortable !== false
              ? setSortField(head.value)
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.1em;
                color: #1b1b1b;
                cursor: pointer;
                user-select: none;
              "
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <v-row no-gutters justify="center"></v-row>
        <span class="tableItem">{{ item.id }}</span>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <span class="tableItem"
          >{{ item?.first_name }} {{ item?.last_name }}</span
        >
      </template>
      <template v-slot:[`item.phone_number`]="{ item }">
        <span class="tableItem">{{ item?.phone_number }}</span>
      </template>
      <template v-slot:[`item.order_count`]="{ item }">
        <span class="tableItem"> {{ item?.order_count }}</span>
      </template>
      <template v-slot:[`item.is_popular`]="{ item }">
        <div style="width: 140px">
          <v-row no-gutters align="center" justify="center">
            <v-switch inset v-model="item.is_popular" />
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-menu offset-y rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="statusBox statusName"
              v-bind="attrs"
              v-on="on"
              :style="
                item.status == 'Active'
                  ? '  color: #149e51;'
                  : '  color: #DF3A3A'
              "
            >
              {{ item.status == "Active" ? "Активний" : "Деактивовано" }}
              <v-icon
                size="18px"
                :color="item.status == 'Active' ? '#149E51' : '#DF3A3A'"
                >mdi-menu-down</v-icon
              >
            </div>
          </template>
          <div
            style="
              border-radius: 9px;
              background: rgba(223, 58, 58, 0.1);
              padding: 8px 16px;
              display: grid;
              place-items: center;
            "
            class="statusName"
            :style="
              item.status !== 'Active'
                ? '  color: #149E51;'
                : '  color: #DF3A3A;'
            "
            @click="changeRouteStatus(item)"
          >
            {{ item.status !== "Active" ? "Активний" : "Деактивовано" }}
          </div>
        </v-menu>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row no-gutters align="center" justify="end">
          <div
            class="detailBtn"
            style="margin-right: 12px"
            @click="(showDetailUserDrawer = true), (detailUser = item)"
          >
            Детальніше
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="iconBackground">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="#828282"
                  class="pointer"
                  @click="
                    (symlinks = item.symlinks), (showDetailSymlinkModal = true)
                  "
                  >mdi-dots-vertical</v-icon
                >
              </div>
            </template>
            <span>Деталі</span>
          </v-tooltip>
        </v-row>
      </template>
    </v-data-table>
    <detail-user-drawer
      style="margin-top: 5svh"
      v-if="showDetailUserDrawer"
      :user="detailUser"
      @close="showDetailUserDrawer = false"
    />
  </v-col>
</template>
  
  <script>
import detailUserDrawer from "./detailUserDrawer.vue";
export default {
  components: { detailUserDrawer },
  data: () => ({
    sortBy: "",
    sortDesc: "",
    headers: [
      {
        text: "№",
        align: "start",
        value: "id",
        sortable: true,
      },
      { text: "Користувач", value: "user", align: "start", sortable: false },
      {
        text: "Номер телефону",
        value: "phone_number",
        align: "start",
        sortable: false,
      },
      {
        text: "Електронна адреса",
        value: "email",
        align: "center",
        sortable: false,
      },
      {
        text: "Поїздки (к-сть)",
        value: "order_count",
        align: "center",
        sortable: true,
      },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    detailUser: {},
    showDetailUserDrawer: false,
  }),
  props: {
    users: {
      require: true,
    },
    loading: {
      require: true,
    },
  },
  methods: {
    setSortField(value) {
      this.sortBy = value;
      this.sortDesc = !this.sortDesc;
      this.$emit("sortBy", this.sortBy, this.sortDesc);
    },
    // async changeRouteStatus(item) {
    //   console.log("item", item);
    //   let data = {
    //     departure: item.departure,
    //     destination: item.destination,
    //     status: item.status !== "Active" ? "Active" : "Archive",
    //     departure_days: item.departure_days,
    //     driver_phones: item.driver_phones,
    //     route_cities: item.route_cities.map(
    //       (route,index) =>
    //         (route = {
    //           id: route.id,
    //           arrival: route.arrival,
    //           //departure_time: route.arrival,
    //           city_uuid: route?.city_uuid,
    //           time_from_start: route?.time_from_start,
    //           bus_station_uuid: route?.bus_station_uuid,
    //           sequence: index,
    //           type: route.type,
    //           platform: route.platform,
    //         })
    //     ),
    //     bus_id: item.bus_id,
    //     route_path_image_id: item.image.id,
    //   };
    //   let form = requestFormData.jsonToFormData(data);
    //   await usersService.updateRoute(item.id, form).then((res) => {
    //     if (res.status == "Success") {
    //       this.$emit("getRoutes");
    //     }
    //   });
    // },
  },
};
</script>
  
  <style scoped>
.statusBox {
  border-radius: 10px;
  background: rgba(20, 158, 81, 0.1);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.statusName {
  color: #149e51;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  user-select: none;
  cursor: pointer;
}
.editIcon {
  width: 24px;
  height: 24px;
  background-color: #4b5262;
  -webkit-mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat center;
  cursor: pointer;
}
.iconBackground:hover .editIcon {
  background-color: #fafafa;
}
.detailBtn {
  height: 36px;
  padding: 4px 8px;
  border-radius: 10px;
  background: #e7edf6;
  color: #4b5262;
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-weight: 400;
  display: grid;
  place-items: center;
  cursor: pointer;
  user-select: none;
}
.detailBtn:hover {
  background: #144fa9;
  color: #fafafa;
}
</style>
  <style>
.iconBackground:hover .theme--light.v-icon,
.theme--dark.v-icon {
  color: #fafafa !important;
}
</style>